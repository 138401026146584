import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const EmployeesDisplayIcon = React.forwardRef(
  function EmployeesDisplayIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={64}
              height={64}
              viewBox='0 0 64 64'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'xl'].className
              )}
            >
              <path
                d='M17 38.66a8.5 8.5 0 100-17 8.5 8.5 0 000 17zM7 43.66a12.5 12.5 0 0120 0'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
              />
              <path
                d='M23 51.66a19.24 19.24 0 0117-11 19.24 19.24 0 0117 11'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
              />
              <path
                d='M40 40.66c7.82 0 14.16-6.34 14.16-14.16S47.82 12.34 40 12.34 25.84 18.68 25.84 26.5 32.18 40.66 40 40.66z'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
              />
              <path
                d='M45.74 31.99a6.58 6.58 0 01-5.61 2.81 6.6 6.6 0 01-5.62-2.81'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
